import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';
import IllustrationPlayer from '@/design-system/molecules/IllustrationPlayer';

import styles from './Testimonial.module.scss';

/**
A testimonial callout is used on content pages to highlight a patient’s story. Can be used as a stand alone banner or as a widget within the Card & Text Component.
 **/
const Testimonial = ({
    title = 'Patient Stories',
    quote,
    attribution,
    cta,
    media = 'image',
    image,
    illustration,
    overrideImage,
    analytics,
    ...props
}) => {
    const mediaComponent = React.useMemo(() => {
        if (!overrideImage || (overrideImage && media === 'image' && image)) {
            return (
                <Image
                    className={styles.image}
                    {...image}
                    roundedCorners={true}
                    defaultAspectRatio="16/9"
                    mobileAspectRatio="16/9"
                />
            );
        }

        if (
            overrideImage &&
            media === 'illustration' &&
            illustration?.scene &&
            illustration?.title
        ) {
            return <IllustrationPlayer {...illustration} />;
        }

        return null;
    }, [media, image, illustration, overrideImage]);

    return (
        <div className={styles[`testimonial__content-container`]} {...props}>
            <ContentContainer className={styles[`testimonial__spacing-wrapper`]}>
                <Theme name={Theme.NAME.POSITIVE} className={styles.testimonial}>
                    <Grid isContainerQuery verticalAlignment="center">
                        <GridColumn
                            colSpan={{ sm: 4, md: 3, lg: 8 }}
                            className={styles[`testimonial__content`]}
                        >
                            <div className={styles['testimonial__content-inner']}>
                                <Title
                                    tag={Title.TAG.H2}
                                    variant={Title.VARIANT.H6}
                                    content={title}
                                    lineClamp={{ sm: 1, lg: 1 }}
                                />
                                <div>
                                    {quote && (
                                        <Text
                                            tag={Text.TAG.P}
                                            variant={Text.VARIANT.T1}
                                            content={`"${quote}"`}
                                        />
                                    )}
                                    {attribution && (
                                        <Text
                                            tag={Text.TAG.P}
                                            variant={Text.VARIANT.T2}
                                            content={attribution}
                                            className={styles[`testimonial__attribution`]}
                                        />
                                    )}
                                </div>
                                {cta && (
                                    <Button
                                        {...cta}
                                        buttonStyle={Button.STYLE.TEXT}
                                        className={styles[`testimonial__cta`]}
                                        analytics={{
                                            componentName: 'Testimonials',
                                            componentTitle: title,
                                            interactionType: 'internal_link',
                                            selector: 'testimonial',
                                            ...analytics,
                                        }}
                                    />
                                )}
                            </div>
                        </GridColumn>
                        <GridColumn
                            className={classnames(
                                styles[`testimonial__media`],
                                styles[`testimonial__media--${media}`],
                            )}
                            colSpan={{ sm: 4, md: 3, lg: 4 }}
                        >
                            {mediaComponent}
                        </GridColumn>
                    </Grid>
                </Theme>
            </ContentContainer>
        </div>
    );
};

Testimonial.propTypes = {
    /**
     * Title, Optional with default value of "Patient Stories"
     */
    title: PropTypes.string,
    /**
     * Quote, Required
     */
    quote: PropTypes.string.isRequired,
    /**
     * Attribution, Optional
     */
    attribution: PropTypes.string,
    /**
     * CTA, Optional
     */
    cta: customPropTypes.linkPropType,
    /**
     * Media, Required
     */
    media: PropTypes.oneOf(['image', 'illustration']).isRequired,
    /**
     * Image
     */
    image: PropTypes.shape(Image.propTypes),
    /**
     * Illustration
     */
    illustration: PropTypes.shape({}),
    /**
     * Override CF image
     */
    overrideImage: PropTypes.bool,
};
export default Testimonial;
